.container {
  background-color: #f7f7f7;
  min-height: 100vh;
}

.content {
  background-color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
  margin-top: 40px;
}

.logo-text {
  color: #3f51b5;
  font-size: 20px;
}

.image-container {
  text-align: center;
}

.logo-image {
  height: 240px;
  width: 250px;
}

.subtitle {
  font-size: 30px;
}

.subtitle-text {
  font-size: 20px;
}

.input-container {
  position: relative;
  width: 90%;
  max-width: 780px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.input {
  background-color: white;
  outline: none;
  font-size: 14px;
  color: #212121;
  font-family: Montserrat;
  border-width: 1px;
  border-radius: 30px;
  padding: 10px 30px 10px 10px;
  width: 100%;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 30%;
  transform: translateY(-50%);
  color: #888;
  pointer-events: none;
  animation: spin 1s linear infinite;
}

.bri-logo {
  height: 100px;
  width: 100px;
}

.footer {
  padding: 15px;
  background-color: #f7f7f7;
  text-align: center;
}

.image-container-landing {
  width: 100%;
}

.logo-image {
  min-width: 30%;
  height: auto;
  object-fit: contain;
}

.transaction-container {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

body {
  background-color: #f7f7f7;
}

p {
  padding: 0;
  margin: 0;
  font-family: 'Inter', Courier, monospace;
}

span {
  font-family: 'Inter', Courier, monospace;
}

button {
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
}

.custom-button {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  outline: none;
}

.custom-button span {
  color: #344054;
  font-size: 11px;
  font-weight: bold;
}

.button-wrap {
  margin-top: 10px;
}

.small-card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  cursor: pointer;
}

.card-content {
  background-color: white;
  height: 200px;
  padding: 20px;
  width: 150px;
}

.card-image {
  width: 100%;
  height: 64px;
  object-fit: contain;
}

.card-type {
  font-size: 10px;
  color: #006386;
  font-weight: bold;
}

.card-title {
  margin-top: 10px;
  font-size: 12px;
}

.card-info {
  font-size: 10px;
  color: #475467;
  margin-top: 5px;
}

.user-info {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-size: 12px;
  font-weight: bold;
  word-wrap: break-word;
}

.user-time {
  font-size: 10px;
  color: #475467;
}

.margin-bottom {
  margin-bottom: 15px;
}

.data-container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.data-card {
  background-color: white;
  min-width: 200px;
  border-radius: 8px;
}

.data-content {
  display: flex;
  flex: 1;
  align-items: center;
  background-color: #f3feff;
  width: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.data-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
}

.data-details {
  padding-left: 10px;
  flex: 1;
}

.data-name {
  font-weight: bold;
  font-size: 16px;
}

.data-info {
  font-size: 14px;
}

.data-type {
  font-size: 14px;
  color: #006386;
  font-weight: bold;
}

.data-user-info {
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 15px;
}

.data-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.data-user-details {
  margin-left: 10px;
  max-width: calc(100% - 50px);
}

.data-user-name {
  font-size: 14px;
  font-weight: 500;
}

.data-user-time {
  font-size: 12px;
  color: #475467;
}

.data-location-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.data-location-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.data-location-text {
  font-size: 14px;
  color: #475467;
  margin-left: 5px;
}

.data-button-wrap {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.data-custom-button {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.data-custom-button-text {
  color: #344054;
  font-size: 11px;
  font-weight: bold;
}

.data-margin-bottom {
  margin-bottom: 20px;
}

.marker-container {
  width: 55px;
  height: 55px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px;
}

.marker-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.marker-button img {
  width: 32px;
  height: 32px;
}

.transfer-container {
  max-width: 1000px;
  margin: 0 auto;
}

.transfer-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.parent-container {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-busy {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.busy-container,
.error-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.render-transfer-wrap {
  align-items: 'center';
  justify-content: 'center';
  display: 'flex';
}

.container-home {
  height: 100vh;
  display: flex;
  flex: 1;
}

.flex-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  align-items: 'center';
  justify-content: 'center';
  display: 'flex';
  flex: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.user-details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.user-time {
  color: gray;
}

.button-custom {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 12px;
  cursor: pointer;
}

.container-button {
  border-top: 1px solid #eaecf0;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-wrap {
  border-top: 1px solid #eaecf0;
  margin-top: 20px;
  padding-top: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.half-flex {
  flex: 0.49;
}

.full-flex {
  flex: 1;
}

.medium-text {
  font-size: 12px;
}

.placeholder-color {
  color: #475467;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.small-margin-top {
  margin-top: 10px;
}

.half-width {
  width: 50%;
}

.flex-center {
  justify-content: 'center';
  display: 'flex';
  flex-direction: row;
}
