.flex-center {
  justify-content: center;
  display: flex;
}

.without-margin {
  margin: 0 auto;
}

.render-data-wrap {
  background-color: white;
  min-width: 350px;
  border-radius: 8px;
}

.unit-wrap {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f3feff;
}

.small-left {
  margin-left: 10px;
}

.regular-padding {
  padding: 20px;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.regular-margin-top {
  margin-top: 20px;
}

.text-center {
  text-align: center;
}

.map-wrap {
  height: 70vh;
  background-color: white;
}

.display-flex {
  display: flex;
}

.custom-flex {
  flex: 0.1;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-container-map {
  height: 70vh;
  flex: 1;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}

.chevron-right {
  flex: 0.1;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}
