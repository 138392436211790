.farmer-card {
  background-color: #ffffff;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .farmer-card {
    width: calc(25% - 16px);
  }
}

@media (max-width: 1005px) {
  .farmer-card {
    width: calc(100% - 16px);
    max-width: 345px;
  }
}

.card-wrap {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.icon-container {
  padding: 4px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.icon-container img {
  width: 32px;
  height: 32px;
}

.icon-wrap {
  background-color: #ecfaff;
  padding: 20px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mr-2 {
  margin-right: 10px;
}

.detail-farmer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.detail-farmer__content {
  width: 100%;
  max-width: 720px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detail-farmer__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.detail-farmer__description {
  font-size: 16px;
  color: #666;
}

.regular-padding-horizontal {
  padding-right: 16px;
  padding-left: 16px;
}

.regular-padding-vertical {
  padding-top: 16px;
  padding-bottom: 16px;
}

.small-border-radius {
  border-radius: 8px;
}

.text-center {
  text-align: center;
}

.back-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-wrap {
  flex: 1;
  width: 100%;
  max-width: 760px;
}

.cursor-pointer {
  cursor: pointer;
}

.image-list-container-farmer {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* scrollbar-width: none; */
  gap: 10px;
}

/* .image-list-container-farmer::-webkit-scrollbar {
  display: none;
} */

.image-item-farmer {
  min-width: 127px;
  /* height: auto; */
  max-width: 130px;
}

.image-farmer {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 100px;
  cursor: pointer;
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.full-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border-radius: 0px 0px 0px 10px;
}
