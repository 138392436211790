.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: white;
}

.content-wrap {
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.left {
  padding: 0.5rem;
  white-space: nowrap;
  position: relative;
  flex: 1;
  max-width: 25%;
  overflow-y: auto;
  height: 73vh;
  min-width: 350px;
}

.right {
  padding: 1.5rem;
  background-color: white;
  box-sizing: border-box;
  flex: 1;
}

.left::-webkit-scrollbar {
  display: none;
}

.left {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.left::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .content-wrap {
    flex-direction: column;
  }

  .left {
    display: flex;
    max-width: 100%;
    padding: 0px;
    overflow-x: auto;
  }

  .right {
    min-width: 100%;
  }
}

.footer-wrap {
  background-color: #ecfaff;
  padding: 1rem;
}

.qrcode-wrap {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.label-menu {
  padding-left: 1rem;
  display: flex;
  align-items: 'center';
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-4 {
  margin-right: 20px;
}

.mt-3 {
  margin-top: 15px;
}

.mb-3 {
  margin-bottom: 15px;
}

.pb-1 {
  padding-bottom: 1rem;
}

.border-bottom {
  border-bottom: 2px solid #dfdfdf;
}

.menu-option {
  padding-left: 1rem;
  padding-top: 2rem;
  display: flex;
  border-bottom: 2px solid #dfdfdf;
  background-color: white;
}

.menu-card {
  padding-bottom: 1rem;
  border-bottom: 3px solid #005370;
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.part-footer {
  display: block;
}

@media (min-width: 768px) {
  .part-footer {
    display: flex;
    align-items: center;
  }
}

.desktop-only {
  display: none;
}

@media (min-width: 768px) {
  .desktop-only {
    display: block;
  }
}

.icon-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  display: block;
  max-width: 100%;
  height: auto;
}

.whatsapp-icon {
  width: 32px;
  height: 32px;
}

.playstore-icon {
  width: 159px;
  height: 70px;
}

@media (max-width: 768px) {
  .whatsapp-icon {
    width: 20px;
    height: 20px;
  }

  .playstore-icon {
    width: 120px;
    height: 50px;
  }
}

.company-logo {
  display: block;
  max-width: 100%;
  height: auto;
  width: 160px;
  height: 32px;
}

@media (max-width: 768px) {
  .company-logo {
    width: 120px;
    height: 24px;
  }
}
