.content-farmers {
  height: 65vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.farmers-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 1005px) {
  .content-farmers {
    padding: 8px;
  }

  .farmers-container {
    flex-direction: column;
    align-items: center;
  }
}

.detail-profile-wrap {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.icon-container-detail {
  padding: 6px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.icon-container-detail img {
  width: 48px;
  height: 48px;
}

.icon-wrap-detail {
  background-color: #ecfaff;
  padding: 20px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-4 {
  margin-top: 20px;
}

.mt-2 {
  margin-top: 10px;
}

.border-bottom {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.image-list-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  gap: 16px;
}

.image-item {
  flex-shrink: 0;
  width: 230px;
  /* height: 200px; */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .image-list-container {
    display: block;
    overflow-x: visible;
  }

  .image-item {
    width: 50%;
    height: auto;
    margin-bottom: 16px;
  }
}
